<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">정산정보 조회(과금부서별)</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">정산정보 목록</h3>
            <div class="float-right">
              <base-button type="primary" @click="excelDownload">엑셀 다운로드</base-button>
            </div>
          </template>
          <div>
            <div class="col-lg-20 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="form-inline " style="margin-bottom: 1rem;">
                <div class="mb-0 col-md-4 form-group pl-0 pr-0" v-if="session.clientType == 'R'">
                  <label class="col-md-4 col-form-label form-control-label">고객사</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.subClientId"
                                 filterable
                                 placeholder="고객사 선택">
                        <el-option v-for="option in clientNameList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 form-group pl-0 pr-0">
                  <label class="col-md-3 col-form-label form-control-label"><span class="text-danger">*</span>정산일시</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="col-4 pl-0 ">
                      <el-select v-model="search.dateTypeYearValue"
                                 filterable
                                 placeholder="연도 선택"
                                 @change="resetDate('YEAR')">
                        <el-option v-for="option in yearList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-4 pl-0">
                      <el-select v-model="search.dateTypeMonthValue"
                                 filterable
                                 placeholder="월 선택"
                                 @change="() => { resetDate('MONTH'); retrieveTaxCdList(); }">
                        <el-option v-for="option in monthList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-4 pl-0">
                      <el-select v-model="search.dateTypeDayValue"
                                 filterable
                                 placeholder="일 선택"
                                 @change="retrieveTaxCdList()">
                        <el-option v-for="option in dayList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="form-inline " style="margin-bottom: 1.5rem;">
                <div class="mb-0 col-md-6 form-group pl-0 pr-0">
                  <label class="col-md-4 col-form-label form-control-label">TAXCD1</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.taxCd1"
                                 filterable
                                 placeholder="== 선택 ==">
                        <el-option
                          value=""
                          label="== 선택 =="></el-option>
                        <el-option v-if="taxCd1List.length > 0"
                                   v-for="option in taxCd1List"
                                   :key="option.taxCd1"
                                   :label="option.taxCd1"
                                   :value="option.taxCd1">
                        </el-option>
                        <el-option v-else
                                   :label="'== 선택 =='"
                                   :value="''">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="mb-0 col-md-6 form-group pl-0 pr-0">
                  <label class="col-md-4 col-form-label form-control-label">TAXCD2</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.taxCd2"
                                 filterable
                                 placeholder="== 선택 ==">
                        <el-option
                          value=""
                          label="== 선택 =="></el-option>
                        <el-option v-if="taxCd2List.length > 0"
                                   v-for="option in taxCd2List"
                                   :key="option.taxCd2"
                                   :label="option.taxCd2"
                                   :value="option.taxCd2">
                        </el-option>
                        <el-option v-else
                                   :label="'== 선택 =='"
                                   :value="''">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <base-button size="lg" outline type="primary" @click="retrieveSettlementTaxList">검색</base-button>
              </div>
            </div>

            <el-table :data="settlementTaxList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.83rem'}"
                      :empty-text="'조회된 정산정보가 없습니다.'"
                      style="width: 100%;overflow-x: auto; padding: 5px 5px 5px 5px;">
              <el-table-column prop="settlementYear" label="연" width="100"></el-table-column>
              <el-table-column prop="settlementMonth" label="월" width="100"></el-table-column>
              <el-table-column prop="settlementDay" label="일" width="100"></el-table-column>
              <el-table-column prop="taxCd1" label="taxCd1" width="150">
                <template slot-scope="scope"><span v-if="scope.row.settlementYear !== '총합'">{{ scope.row.taxCd1 }}</span></template>
              </el-table-column>
              <el-table-column prop="taxCd2" label="taxCd2" width="150">
                <template slot-scope="scope"><span v-if="scope.row.settlementYear !== '총합'">{{ scope.row.taxCd2 }}</span></template>
              </el-table-column>
              <el-table-column prop="at" label="알림톡" >
                <el-table-column prop="atQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.atQuantity | convertCommaNumber }}
                    <span v-if="scope.row.atFailQuantity !== 0 && scope.row.atFailQuantity !== null">({{ scope.row.atFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="at" label="알림톡 대체발송" style="text-align: center">
                <el-table-column prop="smsSubstitutionQuantity" label="SMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.smsSubstitutionFailQuantity !== 0 && scope.row.smsSubstitutionFailQuantity !== null">({{ scope.row.smsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="lmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.lmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.lmsSubstitutionFailQuantity !== 0 && scope.row.lmsSubstitutionFailQuantity !== null">({{ scope.row.lmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ai" label="이미지 알림톡">
                <el-table-column prop="aiQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.aiQuantity | convertCommaNumber }}
                    <span v-if="scope.row.aiFailQuantity !== 0 && scope.row.aiFailQuantity !== null">({{ scope.row.aiFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ai" label="이미지 알림톡 대체발송" >
                <el-table-column prop="aiSmsSubstitutionQuantity" label="SMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.aiSmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.aiSmsSubstitutionFailQuantity !== 0 && scope.row.aiSmsSubstitutionFailQuantity !== null">({{ scope.row.aiSmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="aiLmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.aiLmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.aiLmsSubstitutionFailQuantity !== 0 && scope.row.aiLmsSubstitutionFailQuantity !== null">({{ scope.row.aiLmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ft" label="친구톡" >
                <el-table-column prop="ftQuantity" label="텍스트 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftFailQuantity !== 0 && scope.row.ftFailQuantity !== null">({{ scope.row.ftFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="ftImageQuantity" label="이미지 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftImageQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftImageFailQuantity !== 0 && scope.row.ftImageFailQuantity !== null">({{ scope.row.ftImageFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="ftWimageQuantity" label="와이드 이미지 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftWimageQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftWimageFailQuantity !== 0 && scope.row.ftWimageFailQuantity !== null">({{ scope.row.ftWimageFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ft" label="친구톡 대체발송" style="text-align: center">
                <el-table-column prop="ftSmsSubstitutionQuantity" label="SMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftSmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftSmsSubstitutionFailQuantity !== 0 && scope.row.ftSmsSubstitutionFailQuantity !== null">({{ scope.row.ftSmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="lmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftLmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftLmsSubstitutionFailQuantity !== 0 && scope.row.ftLmsSubstitutionFailQuantity !== null">({{ scope.row.ftLmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="bt" label="브랜드톡" >
                <el-table-column prop="btQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.btQuantity | convertCommaNumber }}
                    <span v-if="scope.row.btFailQuantity !== 0 && scope.row.btFailQuantity !== null">({{ scope.row.btFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="sms" label="SMS" >
                <el-table-column prop="smsQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsQuantity | convertCommaNumber }}
                    <span v-if="scope.row.smsFailQuantity !== 0 && scope.row.smsFailQuantity !== null">({{ scope.row.smsFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="lms" label="LMS" >
                <el-table-column prop="lmsQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.lmsQuantity | convertCommaNumber }}
                    <span v-if="scope.row.lmsFailQuantity !== 0 && scope.row.lmsFailQuantity !== null">({{ scope.row.lmsFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="mms" label="MMS" >
                <el-table-column prop="mmsQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.mmsQuantity | convertCommaNumber }}
                    <span v-if="scope.row.mmsFailQuantity !== 0 && scope.row.mmsFailQuantity !== null">({{ scope.row.mmsFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="isms" label="국제SMS" >
                <el-table-column prop="smsUccQuantity" label="미국/캐나다/중국 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsUccQuantity | convertCommaNumber }}
                    <span v-if="scope.row.smsUccFailQuantity !== 0 && scope.row.smsUccFailQuantity !== null">({{ scope.row.smsUccFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="smsJvQuantity" label="일본/베트남 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsJvQuantity | convertCommaNumber }}
                    <span v-if="scope.row.smsJvFailQuantity !== 0 && scope.row.smsJvFailQuantity !== null">({{ scope.row.smsJvFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="smsIeQuantity" label="기타 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsIeQuantity | convertCommaNumber }}
                    <span v-if="scope.row.smsIeFailQuantity !== 0 && scope.row.smsIeFailQuantity !== null">({{ scope.row.smsIeFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import commJsUtilMixin from '@/shared/mixins/commJsUtil'
import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const'
import {mapGetters} from "vuex";

  export default {
    name: 'SettlementTax',
    mixins: [ commFlatpickrMixin, commJsUtilMixin ],
    components: {
      [Select.name] : Select,
      [Option.name] : Option,
      [Table.name]  : Table,
      [TableColumn.name]: TableColumn
    },
    computed : {
      ...mapGetters({
        session: 'SessionStore/getSession'
      })
    },
    created() {
      // 연도 목록 세팅
      let yearObj   = this._years();
      this.yearList = yearObj.list;
      this.search.dateTypeYearValue = yearObj.year

      // 월 목록 세팅
      let monthObj    = this._months();
      this.monthList  = monthObj.list;
      this.search.dateTypeMonthValue = monthObj.month

      // 일 목록 세팅
      let fullDays = this._fullDays('Y', 1);
      this.dayList = fullDays.dayList;
      this.search.dateTypeDayValue = fullDays.day

      // 고객사 목록 조회
      if(this.session.clientType == 'R'){
        this.retrieveSubClientNameList();
      }
      this.retrieveTaxCdList()
      // 파라미터 설정
      this.setParams()
    },
    data() {
      return {
        settlementTaxList: [],
        clientNameList: [],
        monthList: [],
        yearList: [],
        selectDateOptions: [
          {
            label: '연별',
            value: 'YEAR'
          },
          {
            label: '월별',
            value: 'MONTH'
          }
        ],
        taxCd1List: [],
        taxCd2List: [],
        search: {
          dateTypeCondition : 'MONTH',
          dateTypeDateValue : this.$moment(new Date()).format('YYYY-MM-DD'),
          dateTypeMonthValue: null,
          dateTypeYearValue : null,
          dateTypeDayValue  : null,
          subClientId       : null ,
          taxCd1            : null,
          taxCd2            : null
        }
      }
    },
    methods: {
      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },
      /**
       * tax list 정보 조회
       */
      retrieveTaxCdList() {
        /*초기화*/
        this.search.taxCd1 = '';
        this.search.taxCd2 = '';

        this.$axios.get(`${USER_API_PREFIX}/settlement/taxCdList`, {
          params: {
            settlementYear  : this.search.dateTypeYearValue,
            settlementMonth : this.search.dateTypeMonthValue,
            settlementDay   : this.search.dateTypeDayValue
          }
        }).then((res) => {
          this.taxCd1List = res.data.result.taxCd1List;
          this.taxCd2List = res.data.result.taxCd2List;
        }).catch((error) => {
          console.log(error)
        })
      },
      /**
       * 정산정보 목록 조회
       */
      retrieveSettlementTaxList() {
        let chk = true;
        if(this.checkNull(this.search.dateTypeYearValue)) {
          this.alertMsg('연도를 선택해 주세요.')
          chk = false;
          return false;
        }
        if(this.checkNull(this.search.dateTypeMonthValue)) {
          this.alertMsg('월을 선택해 주세요.')
          chk = false;
          return false;
        }

        if(chk){
          this.$axios.get(`${USER_API_PREFIX}/settlement/settlementTaxList`, {
            params: this.setParams()
          }).then(res => {
            this.settlementTaxList = res.data.result.settlementTaxList; // 목록정보 세팅
          });
        }
      },
      /**
       * null check
       */
      checkNull(param){
        if(param === undefined || param === null || '' === param){
          return true;
        }else{
          return false;
        }
      },

      excelDownload(){
        if(this.search.clientId === null) {
          this.alertMsg('고객사를 선택해 주세요.')
          return false
        }
        this.$axios.get(`${USER_API_PREFIX}/settlement/excelDownTaxCd`, {
          params: this.setParams(),
          responseType: 'blob'
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.headers['content-disposition'].replace("attachment; filename=", ""));
          link.click();
          window.URL.revokeObjectURL(url);
        }).catch((error) => {
          console.log(error)
        });
      },
      /**
       * 연도/월/일자에 선택에 따른 날짜 초기화
       */
      resetDate(period) {
        switch (period) {
          case 'YEAR' :
            this.search.dateTypeMonthValue = ''
            this.search.dateTypeDayValue = ''
            break
          case 'MONTH' :
            this.search.dateTypeDayValue = ''
            break
        }
      },
      /**
       * 일별/월별에 따른 파라미터 세팅
       */
      setParams() {
        return {
          settlementYear  : this.search.dateTypeYearValue,
          settlementMonth : this.search.dateTypeMonthValue,
          settlementDay   : this.search.dateTypeDayValue,
          taxCd1          : this.search.taxCd1,
          taxCd2          : this.search.taxCd2,
          subClientId     : this.search.subClientId
        }
      }
    }
  }
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
