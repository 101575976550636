<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">정산정보 조회</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">정산정보 목록</h3>
            <div class="float-right">
              <base-button type="primary" @click="excelDownload">엑셀 다운로드</base-button>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="form-inline " style="margin-bottom: 1.5rem;">
                <div class="mb-0 col-md-12 form-group pl-0 pr-0">
                  <div class="mb-0 col-md-4 form-group pl-0 pr-0" v-if="session.clientType == 'R'">
                    <label class="col-md-4 col-form-label form-control-label">고객사</label>
                    <div class="col-md-8 pl-0 pr-0 form-inline">
                      <div class="pl-0">
                        <el-select v-model="search.subClientId"
                                   filterable
                                   placeholder="고객사 선택">
                          <el-option v-for="option in clientNameList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                  <label class="col-md-2 col-form-label form-control-label">일시</label>
                  <div class="col-md-5 pl-0 pr-0 form-inline">
                    <div class="col-7 pl-0">
                      <el-select v-model="search.dateTypeYearValue"
                                 filterable
                                 placeholder="연도 선택">
                        <el-option v-for="option in yearList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-5 pl-0 pr-0">
                      <el-select v-model="search.dateTypeMonthValue"
                                 filterable
                                 placeholder="월 선택">
                        <el-option v-for="option in monthList"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <base-button size="lg" outline type="primary" @click="retrieveSettlementResultList">검색</base-button>
              </div>
            </div>

            <el-table :data="settlementResultList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.83rem'}"
                      :empty-text="'조회된 정산정보가 없습니다.'"
                      style="width: 100%;overflow-x: auto; padding: 5px 5px 5px 5px;">
              <el-table-column prop="settlementYear" label="연" width="100"></el-table-column>
              <el-table-column prop="settlementMonth" label="월" width="100"></el-table-column>
              <el-table-column prop="settlementDay" label="일" width="100"></el-table-column>
              <el-table-column prop="at" label="알림톡" >
                <el-table-column prop="atQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.atQuantity | convertCommaNumber }}
                    <span v-if="scope.row.atFailQuantity !== 0 && scope.row.atFailQuantity !== null">({{ scope.row.atFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="at" label="알림톡 대체발송" style="text-align: center">
                <el-table-column prop="smsSubstitutionQuantity" label="SMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.smsSubstitutionFailQuantity !== 0 && scope.row.smsSubstitutionFailQuantity !== null">({{ scope.row.smsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="lmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.lmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.lmsSubstitutionFailQuantity !== 0 && scope.row.lmsSubstitutionFailQuantity !== null">({{ scope.row.lmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ai" label="이미지 알림톡">
                <el-table-column prop="aiQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.aiQuantity | convertCommaNumber }}
                    <span v-if="scope.row.aiFailQuantity !== 0 && scope.row.aiFailQuantity !== null">({{ scope.row.aiFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ai" label="이미지 알림톡 대체발송" >
                <el-table-column prop="aiSmsSubstitutionQuantity" label="SMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.aiSmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.aiSmsSubstitutionFailQuantity !== 0 && scope.row.aiSmsSubstitutionFailQuantity !== null">({{ scope.row.aiSmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="aiLmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.aiLmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.aiLmsSubstitutionFailQuantity !== 0 && scope.row.aiLmsSubstitutionFailQuantity !== null">({{ scope.row.aiLmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ft" label="친구톡" >
                <el-table-column prop="ftQuantity" label="텍스트 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftQuantity | convertCommaNumber }} <span v-if="scope.row.ftFailQuantity !== 0">({{ scope.row.ftFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
                <el-table-column prop="ftImageQuantity" label="이미지 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftImageQuantity | convertCommaNumber }} <span v-if="scope.row.ftImageFailQuantity !== 0">({{ scope.row.ftImageFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
                <el-table-column prop="ftWimageQuantity" label="와이드 이미지 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftWimageQuantity | convertCommaNumber }} <span v-if="scope.row.ftWimageFailQuantity !== 0">({{ scope.row.ftWimageFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="ft" label="친구톡 대체발송" style="text-align: center">
                <el-table-column prop="ftSmsSubstitutionQuantity" label="SMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftSmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftSmsSubstitutionFailQuantity !== 0 && scope.row.ftSmsSubstitutionFailQuantity !== null">({{ scope.row.ftSmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="lmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.ftLmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.ftLmsSubstitutionFailQuantity !== 0 && scope.row.ftLmsSubstitutionFailQuantity !== null">({{ scope.row.ftLmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>
<!--
              <el-table-column prop="bt" label="브랜드톡" >
                <el-table-column prop="btQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.btQuantity | convertCommaNumber }} <span v-if="scope.row.btFailQuantity !== 0">({{ scope.row.btFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>
-->

              <el-table-column prop="smsSub" label="SMS 대체" >
                <el-table-column prop="smsSubstitutionQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsSubstitutionQuantity | convertCommaNumber }} <span v-if="scope.row.smsSubstitutionFailQuantity !== 0">({{ scope.row.smsSubstitutionFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="lmsSub" label="LMS 대체" >
                <el-table-column prop="lmsSubstitutionQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.lmsSubstitutionQuantity | convertCommaNumber }} <span v-if="scope.row.lmsSubstitutionFailQuantity !== 0">({{ scope.row.lmsSubstitutionFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="sms" label="SMS" >
                <el-table-column prop="smsQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.smsQuantity | convertCommaNumber }} <span v-if="scope.row.smsFailQuantity !== 0">({{ scope.row.smsFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="lms" label="LMS" >
                <el-table-column prop="lmsQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.lmsQuantity | convertCommaNumber }} <span v-if="scope.row.lmsFailQuantity !== 0">({{ scope.row.lmsFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="mms" label="MMS" >
                <el-table-column prop="mmsQuantity" label="성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.mmsQuantity | convertCommaNumber }} <span v-if="scope.row.mmsFailQuantity !== 0">({{ scope.row.mmsFailQuantity | convertCommaNumber }})</span></template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="rcs" label="RCS">
                <el-table-column prop="rcsSmsQuantity" label="RCSSMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.rcsSmsQuantity | convertCommaNumber }}
                    <span v-if="scope.row.rcsSmsFailQuantity !== 0 && scope.row.rcsSmsFailQuantity !== null">({{ scope.row.rcsSmsFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="rcsLmsQuantity" label="RCSLMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.rcsLmsQuantity | convertCommaNumber }}
                    <span v-if="scope.row.rcsLmsFailQuantity !== 0 && scope.row.rcsLmsFailQuantity !== null">({{ scope.row.rcsLmsFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="rcsMmsQuantity" label="RCSMMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.rcsMmsQuantity | convertCommaNumber }}
                    <span v-if="scope.row.rcsMmsFailQuantity !== 0 && scope.row.rcsMmsFailQuantity !== null">({{ scope.row.rcsMmsFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="rcsTmplQuantity" label="RCSTMPL 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.rcsTmplQuantity | convertCommaNumber }}
                    <span v-if="scope.row.rcsTmplFailQuantity !== 0 && scope.row.rcsTmplFailQuantity !== null">({{ scope.row.rcsTmplFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column prop="rcs" label="RCS 대체발송">
                <el-table-column prop="rcsSmsSubstitutionQuantity" label="SMS 성공(실패) " width="150">
                  <template slot-scope="scope">{{ scope.row.rcsSmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.rcsSmsSubstitutionFailQuantity !== 0 && scope.row.rcsSmsSubstitutionFailQuantity !== null">({{ scope.row.rcsSmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
                <el-table-column prop="rcsLmsSubstitutionQuantity" label="LMS 성공(실패)" width="150">
                  <template slot-scope="scope">{{ scope.row.rcsLmsSubstitutionQuantity | convertCommaNumber }}
                    <span v-if="scope.row.rcsLmsSubstitutionFailQuantity !== 0 && scope.row.rcsLmsSubstitutionFailQuantity !== null">({{ scope.row.rcsLmsSubstitutionFailQuantity | convertCommaNumber }})</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import commJsUtilMixin from '@/shared/mixins/commJsUtil'
import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const'
import {mapGetters} from "vuex";

export default {
  name: 'SettlementResult',
  mixins: [ commFlatpickrMixin, commJsUtilMixin ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed : {
    ...mapGetters({
      session: 'SessionStore/getSession'
    })
  },
  created() {
    // 연도 목록 세팅
    let yearObj = this._years();
    this.yearList = yearObj.list;
    this.search.dateTypeYearValue = yearObj.year

    // 월 목록 세팅
    let monthObj = this._months('Y');
    this.monthList = monthObj.list;
    this.search.dateTypeMonthValue = monthObj.month

    this.setParams()
    if(this.session.clientType == 'R'){
      this.retrieveSubClientNameList();
    }
  },
  data() {
    return {
      settlementResultList: [],
      monthList: [],
      yearList: [],
      clientNameList : [],
      selectDateOptions: [
        {
          label: '연별',
          value: 'YEAR'
        },
        {
          label: '월별',
          value: 'MONTH'
        }
      ],
      search: {
        subClientId : null ,
        dateTypeCondition: 'MONTH',
        dateTypeDateValue: this.$moment(new Date()).format('YYYY-MM-DD'),
        dateTypeMonthValue: null,
        dateTypeYearValue: null
      }
    }
  },
  methods: {
    /**
     * 정산정보 목록 조회
     */
    retrieveSettlementResultList() {
      this.$axios.get(`${USER_API_PREFIX}/settlement/result`, {
        params: this.setParams()
      }).then(res => {
        this.settlementResultList = res.data.result.settlementResultList; // 목록정보 세팅
      });
    },
    /**
     * 일별/월별에 따른 파라미터 세팅
     */
    setParams() {
      return {
        settlementYear: this.search.dateTypeYearValue,
        settlementMonth: this.search.dateTypeMonthValue,
        subClientId : this.search.subClientId
      }
    },
    /**
     *
     */
    retrieveSubClientNameList() {
      this.$axios.get(`${USER_API_PREFIX}/resell/sub-clients`, {
      }).then((res) => {
        this.clientNameList = res.data.result.clientNameList;
        this.clientNameList.unshift({
          value: '',
          label: '== 전체 =='
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    /**
     * 엑셀 다운로드
     */
    excelDownload(){
      this.$axios.get(`${USER_API_PREFIX}/settlement/excelDown`, {
        params: this.setParams(),
        responseType: 'blob'
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', res.headers['content-disposition'].replace("attachment; filename=", ""));
        link.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }
}
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
